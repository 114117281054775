import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Img from 'gatsby-image'
import styled, { ThemeConsumer } from 'styled-components'
import customProtocolCheck from 'custom-protocol-check'

import Container from '../components/Container'
import { Flex, Box } from 'reflexbox'
import Card from '../components/Card'
import Button from '../components/Button'

const StyledPartnerCallout = styled.li`
  list-style-type: none;

  a {
    font-weight: 600;
    text-decoration: none;
    font-size: 1rem;
  }

  &:hover {
    text-decoration: underline;
  }

  .iconfinder {
    color: #339266;
  }

  .ui8 {
    color: #2f8be6;
  }
`

const FeaturedSet = ({ props, data }) => {
  const setData = data.featuredIconsJson

  function handleDownload(e, href) {
    e.preventDefault()

    if (href.includes('.zip')) {
      customProtocolCheck(
        `iconjar://download?url=${href}`,
        () => {
          window.location = href
        },
        () => {
          console.log('joepie')
        },
        5000
      )
    } else {
      window.location = href
    }
  }

  return (
    <ThemeConsumer>
      {theme => (
        <Container my={[4, 5]}>
          <SEO title={'Featured icons ∙ ' + setData.name} />

          <Flex flexWrap="wrap" flexDirection={['column-reverse', 'row']}>
            <Box width={[1, 3 / 5]} px={[0, 3]}>
              <Card style={{ border: '0' }}>
                <Img
                  fluid={data.image.childImageSharp.fluid}
                  style={{ width: '100%', display: 'block' }}
                />
              </Card>
            </Box>
            <Box width={[1, 2 / 5]} px={[0, 3]} mt={[0, 4]} mb={[4, 0]}>
              <h1>{setData.name}</h1>
              <p>
                Designed by{' '}
                <a href={setData.author.website} rel="nofollow">
                  {setData.author.name}
                </a>
              </p>
              <p dangerouslySetInnerHTML={{ __html: setData.description }} />

              <Flex mb={3}>
                {setData.downloadLinks.sample && (
                  <Box pr={3}>
                    <Button
                      as="a"
                      href={
                        setData.downloadLinks.sample.includes('.zip')
                          ? data.sampleSet.edges[0].node.publicURL
                          : setData.downloadLinks.sample
                      }
                      onClick={e => handleDownload(e, e.target.href)}
                      big>
                      {setData.downloadLinks.externalLink
                        ? 'Download sample'
                        : 'Download set'}
                    </Button>
                  </Box>
                )}

                {setData.downloadLinks.externalLink && (
                  <Box>
                    <Button
                      as="a"
                      href={setData.downloadLinks.externalLink}
                      colorscheme="green"
                      big>
                      Buy icon set
                    </Button>
                  </Box>
                )}
              </Flex>

              <ul style={{ paddingLeft: 0 }}>
                <Flex mb={3} flexWrap="wrap">
                  {setData.downloadLinks.iconfinder && (
                    <StyledPartnerCallout>
                      <a
                        href={setData.downloadLinks.iconfinder}
                        className="iconfinder"
                        target="_blank"
                        rel="noopener noreferrer">
                        Discover this set on Iconfinder &#8599;
                      </a>
                    </StyledPartnerCallout>
                  )}

                  {setData.downloadLinks.ui8 && (
                    <StyledPartnerCallout>
                      <a
                        href={setData.downloadLinks.ui8 + '?rel=icnjr'}
                        className="ui8"
                        target="_blank"
                        rel="noopener noreferrer">
                        Discover this set on UI8 &#8599;
                      </a>
                    </StyledPartnerCallout>
                  )}
                </Flex>
              </ul>
            </Box>
          </Flex>
        </Container>
      )}
    </ThemeConsumer>
  )
}

export default FeaturedSet

export const pageQuery = graphql`
  query($slug: String!) {
    featuredIconsJson: featuredIconsJson(slug: { eq: $slug }) {
      name
      numberOfIcons
      description
      author {
        name
        website
      }
      downloadLinks {
        sample
        externalLink
        iconfinder
        ui8
      }
    }
    image: file(name: { eq: "detail@2x" }, relativeDirectory: { eq: $slug }) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    sampleSet: allFile(
      filter: { extension: { eq: "zip" }, relativeDirectory: { eq: $slug } }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`
